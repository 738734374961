<template>
    <div class="my-2">
        <LangcodeDropdown @languageSelected="get_selected_language" :lang="props.lang"  />
    </div>
    <div class="my-2" v-if="selectedLanguage">
        <Dropdown id="selector" v-model="selectedValue" :options="languageOptions" optionLabel="name" optionValue="value" @change="handleChange" :preselectedValue="preselectedValue" />
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import languages from '../voices_elevenlabs.json';
import LangcodeDropdown from '../Components/Langcode-Dropdown.vue'
const selectedValue = ref(null);
const languageOptions = ref([]);
const emit = defineEmits(['selectedItem']);
const selectedLanguage = ref(null);

const props = defineProps({
    lang: String,
    preselectedValue: String,
});

onMounted(() => {
    const options = Object.entries(languages).map(([name, value]) => ({
        name,
        value,
    }));
    languageOptions.value = options;
    selectedLanguage.value = props.lang;
    selectedValue.value = props.preselectedValue;
});

watch(props, (newProps) => {
    selectedLanguage.value = newProps.lang;
    selectedValue.value = newProps.preselectedValue;
});

const get_selected_language = (data) => {
    //console.log('selected language', data);
    selectedLanguage.value = data;
}

const handleChange = (event) => {
    const selectedOption = event.value;
    const selectedItem = languageOptions.value.find(
        (option) => option.value === selectedOption
    );
    selectedItem.language = selectedLanguage.value
    //console.log(selectedItem);
    emit('selectedVoice', selectedItem);
};
</script>