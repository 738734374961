<template>
    <Toast />
    <ConfirmDialog />

    <Toolbar>

        <template #start>
            <div class="inline sm:hidden">
                <div class="">
                    <SplitButton label="Neu" icon="pi pi-plus" @click="setNew" :model="items" />
                </div>
            </div>
            <div class="hidden sm:flex">
                <Button v-show="changes != 0" v-tooltip.bottom="'Änderungen zurücksetzen'" @click="reset_changes" class="mr-2 p-button-danger" icon="pi pi-refresh" />
                <Button v-show="changes > 0" @click="save_changes" v-tooltip.bottom="'Änderungen speichern'" :label="save_text" class="w-auto  p-button-success mx-2" icon="pi pi-save" />

                <Button @click="setNew" label="Neuer Student" class="w-auto mr-2 p-button" v-tooltip.top="'Ein neuer Datensatz erfassen'" icon="pi pi-plus" />
            </div>
        </template>


    </Toolbar>
    <Toolbar class="mt-2">
        <template #start>
            <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filter_dataset['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
            </span>
        </template>
    </Toolbar>
    <!--------------------------------------------------->
    <!------------------ DataTable ---------------------->
    <!--------------------------------------------------->
    <DataTable class="mt-3" v-model:filters="filter_dataset" :value="dataset" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20, 50, 100]" @rowReorder="onTeamRowReorder" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="{first} bis {last} von {totalRecords}" @row-dblclick="handleRowDoubleClick">
        <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
        <!-- <Column field="status" header="Status">
            <template #body="{ data, field }">
                <span class="inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/10" v-if="data[field] == 'draft'" :label="get_status(data[field])" v-tooltip.top="'Beitrag ist Publiziert'">{{ get_status(data[field]) }}</span>

                <span class="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-900 ring-1 ring-inset ring-green-600/20" v-if="data[field] == 'published'" v-tooltip.top="'Beitrag ist Publiziert'">{{ get_status(data[field]) }}</span>
            </template>
        </Column> -->
        <Column header="Name" class="min-w-48">
            <template #body="slotProps">
                <span class="">{{ slotProps.data.user_firstname }} {{ slotProps.data.user_lastname }}</span>
            </template>
        </Column>

        <Column header="Kurse" class="min-w-48">
            <template #body="slotProps">
                <span class="" v-if="slotProps.data.courses">{{ slotProps.data.courses.length }}</span>
            </template>
        </Column>

        <Column header="Fortschritt" class="min-w-48">
            <template #body="slotProps">
                <span class="" v-if="slotProps.data.progress">({{ slotProps.data.progress}} / 1000)</span>
            </template>
        </Column>
        <Column>
            <template #body="slotProps">
                <Button v-if="force_save == false" @click="handleEditClick(slotProps)" class="p-button-rounded p-button-text" v-tooltip.top="'Studentdaten bearbeiten'" icon="pi pi-pencil" />
                <Button v-if="force_save == false" @click="delete_dataset(slotProps.data.workflow_id)" class="p-button-rounded p-button-text" v-tooltip.top="'Student löschen'" icon="pi pi-trash" />
                <Button v-if="force_save == false" class="p-button-rounded p-button-text" @click="send_login(slotProps.data)" v-tooltip.top="'Login Senden'" icon="pi pi-sign-in" />
            </template>
        </Column>
    </DataTable>

    <div class="flex items-center justify-center m-16" v-show="loader">
        <LoadingSpinner size="12"></LoadingSpinner>
    </div>


    <!------------------- Dialogs ----------------------->
    <Dialog v-model:visible="editDialog" header="Datensatz bearbeiten" :modal="true" class="w-auto md:w-3/4">

        <div class="flex flex-wrap">

            <!-- Column 1 -->
            <div class="w-1/2 px-3">
                <!-- Anrede -->
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="user_salutation">Anrede *</label>
                    <Dropdown v-model="data_user_salutation" :options="remote_salutations" optionLabel="name" :disabled="save_loader" id="user_salutation" class="w-full" required />
                </div>
                <!-- Courses -->
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="courses">Kurse *</label>
                    <MultiSelect v-model="data_courses" :options="courses" optionLabel="name" option-value="workflow_id" :disabled="save_loader" id="courses" class="w-full" required />
                </div>
                <!-- VorName -->
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="name">Vorname *</label>
                    <InputText v-model="data_user_firstname" :disabled="save_loader" id="name" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight " required />
                </div>
                <!-- Last Name -->
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="name">Nachname *</label>
                    <InputText v-model="data_user_lastname" :disabled="save_loader" id="name" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight " required />
                </div>
                <!-- user_email -->
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="email">E-Mail</label>
                    <InputText v-model="data_user_email" type="email" :disabled="save_loader" id="user_email" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight " />
                </div>
            </div>

            <!-- Column 2 -->
            <div class="w-1/2 px-3">
                <!-- Mobile -->
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="mobile">Mobil *</label>
                    <div class="flex flex-wrap">
                        <div class="w-1/4 pr-2">
                            <Dropdown v-model="data_mobile_prefix" :options="mobile_codes" optionLabel="name" option-value="name" class="w-full" :disabled="save_loader" />
                        </div>
                        <div class="w-3/4">
                            <InputText v-model="data_mobile" :disabled="save_loader" id="mobile" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight " required />
                        </div>
                    </div>
                </div>
                <!-- Address -->
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="address">Adresse</label>
                    <InputText v-model="data_address" :disabled="save_loader" id="address" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight " />
                </div>
                <!-- PLZ -->
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="plz">PLZ</label>
                    <InputText v-model="data_plz" :disabled="save_loader" id="plz" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight " />
                </div>
                <!-- City -->
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="city">Stadt</label>
                    <InputText v-model="data_city" :disabled="save_loader" id="city" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight" />
                </div>
                <!-- Country -->
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="country">Land</label>
                    <InputText v-model="data_country" :disabled="save_loader" id="country" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight" />
                </div>
                <!-- Progress -->
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="progressy">Progress</label>
                    <InputNumber v-model="data_student_progress" :disabled="save_loader" id="progress" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight" />
                </div>
            </div>
        </div>
        <!-- Buttons -->
        <div class="flex items-center justify-between mt-4">
            <Button :disabled="!formValid" label="Speichern" @click="save_dataset(data_index, true), editDialog = false" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />

            <Button label="Abbrechen" @click="editDialog = false" class="w-auto mr-3 mb-1 p-button-warning" icon="pi pi-times" />

        </div>
    </Dialog>

    <!------------------ New ---------------------->
    <Dialog v-model:visible="newDialog" header="Student hinzufügen" :modal="true" class=" w-full mx-3 md:w-3/4">

        <div class="w-full mx-auto">

            <div class="flex flex-wrap">

                <!-- Column 1 -->
                <div class="w-1/2 px-3">
                    <!-- Anrede -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="user_salutation">Anrede *</label>
                        <Dropdown v-model="data_user_salutation" :options="remote_salutations" optionLabel="name" :disabled="save_loader" id="user_salutation" class="w-full" required />
                    </div>
                    <!-- Courses -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="courses">Kurse *</label>
                        <MultiSelect v-model="data_courses" :options="courses" optionLabel="name" option-value="workflow_id" :disabled="save_loader" id="courses" class="w-full" required />
                    </div>
                    <!-- VorName -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="name">Vorname *</label>
                        <InputText v-model="data_user_firstname" :disabled="save_loader" id="name" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight " required />
                    </div>
                    <!-- Last Name -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="name">Nachname *</label>
                        <InputText v-model="data_user_lastname" :disabled="save_loader" id="name" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight " required />
                    </div>
                    <!-- user_email -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="user_email">E-Mail</label>
                        <InputText v-model="data_user_email" :disabled="save_loader" id="user_email" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight " />
                    </div>
                </div>

                <!-- Column 2 -->
                <div class="w-1/2 px-3">
                    <!-- Mobile -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="mobile">Mobil *</label>
                        <div class="flex flex-wrap">
                            <div class="w-1/4 pr-2">
                                <Dropdown v-model="data_mobile_prefix" :options="mobile_codes" optionLabel="name" option-value="name" class="w-full" :disabled="save_loader" />
                            </div>
                            <div class="w-3/4">
                                <InputText v-model="data_mobile" :disabled="save_loader" id="mobile" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight " required />
                            </div>
                        </div>
                    </div>
                    <!-- Address -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="address">Adresse</label>
                        <InputText v-model="data_address" :disabled="save_loader" id="address" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight " />
                    </div>
                    <!-- PLZ -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="plz">PLZ</label>
                        <InputText v-model="data_plz" :disabled="save_loader" id="plz" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight " />
                    </div>
                    <!-- City -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="city">Stadt</label>
                        <InputText v-model="data_city" :disabled="save_loader" id="city" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight" />
                    </div>
                    <!-- Country -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="country">Land</label>
                        <InputText v-model="data_country" :disabled="save_loader" id="country" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight" />
                    </div>
                </div>
            </div>

            <!-- Buttons -->
            <div class="flex items-center justify-between mt-4">
                <Button :disabled="!formValid" :loading="save_loader" label="Speichern" @click="new_dataset" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                <Button label="Abbrechen" @click="newDialog = false" :loading="save_loader" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
            </div>
        </div>



    </Dialog>
</template>


<script setup>
import { ref, onMounted, watch, inject, computed } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import Simplestore from '@/core/var/Simplestore';
import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue';
import { FilterMatchMode } from 'primevue/api'
import { useCustomerStore } from '@/core/store/CustomerStore'
import { useConfirm } from "primevue/useconfirm"
import { getRandomId, validateEmail, validatePhoneNumber, checkDuplicate } from '@/core/var/tools'


const CoreRouter = inject('CoreRouter');
const confirm = useConfirm()
// Data
const save_loader = ref(false);


const yesNoOptions = [
    { label: 'Ja', value: true },
    { label: 'Nein', value: false }
];


const toast = useToast()
const SERVICE_URL = inject('SERVICE_URL');
const dataset = ref([])
const data_user_firstname = ref(null)
const data_user_lastname = ref(null)
const data_user_email = ref(null)
const data_mobile_prefix = ref(null)
const data_mobile = ref(null)
const data_address = ref(null)
const data_plz = ref(null)
const data_city = ref(null)
const data_country = ref(null)
const data_index = ref(null)
const data_user_salutation = ref(null)
const data_courses = ref(null)
const data_student_progress = ref(null)

const courses = ref([])

const data_status = ref('draft')
const remote_salutations = ref([])

const data_workflow_id = ref(null)
const changes = ref(0)
const save_text = ref("Änderungen speichern")
const editDialog = ref(false)
const newDialog = ref(false)
const force_save = ref(false)
const loader = ref(false)
const disabled = ref()
const save_status = ref()
const mobile_codes = ref([
    { name: '+41' },
    { name: '+49' },
    { name: '+33' },
    { name: '+39' },
    { name: '+43' },
    { name: '+44' },
    { name: '+45' },
]);


const status = ref([
    { label: 'Aktiv', value: 'published' },
    { label: 'Inaktiv', value: 'draft' }
])

const filter_dataset = ref({ 'global': { value: null, matchMode: FilterMatchMode.CONTAINS } })
const channel = ref(useCustomerStore().getCustomer.customer);
const module = 'students';

const endpoints = {
    get: `${module}/get`,
    getImagelib: `${module}/get-image-library`,
    uploadImage: `${module}/upload-image`,
    uploadLibImage: `${module}/upload-library-image`,
    getsystem: "get-system",
    getcategories: `${module}/categories`,
    gettags: `${module}/tags`,
    getusers: "get-users",
    save: `${module}/save`
}


onMounted(async () => {
    loader.value = true;
    await bootstrap();
    loader.value = false;
})


const bootstrap = async () => {
    try {
        const [dataResponse, dataSalutations, dataCourses] = await Promise.all([
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.get}`),
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/bexio-salutations`),
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/courses/get`),
        ]);
        dataset.value = dataResponse.data;
        remote_salutations.value = dataSalutations.data;
        courses.value = dataCourses.data;
    } catch (error) {
        console.error(error);
    }
}


const items = ref([
    {
        label: save_text,
        icon: 'pi pi-refresh',
        disabled: changes.value > 0 ? false : true,
        command: () => {
            save_changes();
        }
    },
    {
        label: 'Zurücksetzen',
        icon: 'pi pi-times',
        disabled: changes.value != 0 ? false : true,
        command: () => {
            reset_changes();
        }
    },
]);


watch(changes, res => {
    items.value[0].disabled = res > 0 ? false : true;
    items.value[1].disabled = res != 0 ? false : true;
});


watch(loader, res => {
    Simplestore.spinner = res;
    disabled.value = res;
});

const formValid = computed(() => {

    let res = false;

    if (data_user_firstname.value && data_user_lastname.value && data_mobile.value && data_mobile_prefix.value !== null && validatePhoneNumber(data_mobile.value) && validateEmail(data_user_email.value) && data_user_salutation.value && data_courses.value){
        res = true;
    }
    return res
});

const reset_changes = async () => {
    try {
        const response = await axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.get}`);
        dataset.value = response.data;
        changes.value = 0;
        save_text.value = "Änderungen speichern";
        force_save.value = false;
    } catch (error) {
        console.error(error);
    }
};

const onTeamRowReorder = (e) => {
    dataset.value = e.value
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    force_save.value = true
    toast.add({ severity: 'success', summary: 'Reihenfolge geändert', detail: 'Die Reihenfolge wurde erfolgreich geändert', life: 3000 })
}

// const get_status = (status_value) => {
//     if (status_value == "published") {
//         return "Aktiv"
//     } else if (status_value == "draft") {
//         return "Inaktiv"
//     }
// }

const setNew = () => {
    newDialog.value = true;
    data_user_firstname.value = null
    data_user_salutation.value = null
    data_user_lastname.value = null
    data_status.value = 'draft'
    data_user_email.value = null
    data_mobile.value = null
    data_mobile_prefix.value = null
    data_address.value = null
    data_plz.value = null
    data_city.value = null
    data_country.value = null
    data_courses.value = null
    data_student_progress.value = 0

}

const save_dataset = async (index) => {


    //const res = await checkDuplicate(data_user_email.value, data_workflow_id.value,'/dashboard/check-email');
    const res = true
    if(res == true){

        dataset.value[index].workflow_id = data_workflow_id.value;
        dataset.value[index].user_salutation = data_user_salutation.value;
        dataset.value[index].user_firstname = data_user_firstname.value;
        dataset.value[index].user_lastname = data_user_lastname.value;
        dataset.value[index].user_email = data_user_email.value;
        dataset.value[index].mobile = data_mobile.value;
        dataset.value[index].mobile_prefix = data_mobile_prefix.value;
        dataset.value[index].address = data_address.value;
        dataset.value[index].plz = data_plz.value;
        dataset.value[index].city = data_city.value;
        dataset.value[index].courses = data_courses.value;
        dataset.value[index].country = data_country.value;
        dataset.value[index].progress = data_student_progress.value;
        dataset.value[index].status = data_status.value;


        incrementSaves();
    }else{
        toast.add({
            severity: "error",
            summary: "Fehler",
            detail: "Die E-Mailadresse ist bereits vergeben",
            life: 3000
        });
    
    }
}

const new_dataset = async () => {

    data_workflow_id.value = getRandomId(10)

    const append = {
        "workflow_id": data_workflow_id.value,
        "user_salutation": data_user_salutation.value,
        "user_firstname": data_user_firstname.value,
        "user_lastname": data_user_lastname.value,
        "user_email": data_user_email.value,
        "mobile_prefix": data_mobile_prefix.value,
        "mobile": data_mobile.value,
        "address": data_address.value,
        "plz": data_plz.value,
        "city": data_city.value,
        "country": data_country.value,
        "courses": data_courses.value,
        "status": data_status.value,
        "progress": data_student_progress.value

    }

    dataset.value.unshift(append)
    force_save.value = true
    incrementSaves();

    save_status.value = 'create'

    newDialog.value = false

}


const send_login = async (data) => {
    //console.log(data)
    const number = `${data.mobile_prefix}${data.mobile}`
    data.channel = channel.value;

    confirm.require({
        message: `Willst du ${data.user_salutation.name} ${data.user_firstname} ${data.user_lastname}, ${number} Zugang per SMS Login gewähren?`,
        header: 'Zugang gewähren',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja',
        acceptClass: 'p-button-success',
        icon: 'pi pi-sign-in',
        accept: async () => {
            const res = await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/dashboard/send-login-sms`, { number: number, dashboard_url:"https://sprachtechnik.snpy.ch/snappy-sms", metadata: data});
 
            if (res.data.error) {
                toast.add({
                    severity: "error",
                    summary: "Fehler",
                    detail: "Es ist ein Fehler aufgetreten"+res.data.error,
                    life: 3000
                });
            } else {
                toast.add({
                    severity: "success",
                    summary: "SMS gesendet",
                    detail: "Die SMS wurde erfolgreich gesendet",
                    life: 3000
                });
            }
        }
    })
}


const delete_dataset = async (id) => {

    confirm.require({
        message: 'Willst du den Student wirklich löschen?',
        header: 'Student löschen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja!',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
            const newt = dataset.value.filter(dataset => dataset.workflow_id !== id);
            dataset.value = newt;
            incrementSaves();
            data_workflow_id.value = id;
            save_status.value = 'delete'
        }
    })

}

const incrementSaves = () => {
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
}

const save_changes = async () => {

    save_loader.value = true;
    await save_course();
    save_loader.value = false;
};

const handleEditClick = (slotProps) => {

    data_index.value = slotProps.index;
    data_status.value = slotProps.data.status;
    data_workflow_id.value = slotProps.data.workflow_id;
    data_user_salutation.value = slotProps.data.user_salutation;
    data_user_firstname.value = slotProps.data.user_firstname;
    data_user_lastname.value = slotProps.data.user_lastname;
    data_user_email.value = slotProps.data.user_email;
    data_mobile.value = slotProps.data.mobile;
    data_mobile_prefix.value = slotProps.data.mobile_prefix;    
    data_courses.value = slotProps.data.courses;
    data_address.value = slotProps.data.address;
    data_plz.value = slotProps.data.plz;
    data_city.value = slotProps.data.city;
    data_country.value = slotProps.data.country;
    data_student_progress.value = slotProps.data.progress;
    
    editDialog.value = true;
}

function handleRowDoubleClick(event) {
    handleEditClick(event);
}

const save_course = async () => {
    try {
        await axios.post(
            `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.save}`,
            dataset.value);

        toast.add({
            severity: "info",
            summary: "Änderungen gespeichert",
            detail: "Die Änderungen wurden erfolgreich gespeichert",
            life: 3000
        });

        changes.value = 0;
        save_text.value = "Änderungen speichern";
        force_save.value = false;
    } catch (error) {
        console.error(error);
    }
}
</script>
