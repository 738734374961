<template>
    <Dropdown v-model="selectedLanguage" :options="languageOptions" optionLabel="label" optionValue="value" placeholder="Sprache auswählen" @change="emitSelectedLanguage" class="w-full" />
</template>

<script setup>
import { ref, computed } from 'vue';
import languages from '../languages.json';

const props = defineProps({
    lang: {
        type: String,
        default: null
    }
});

const selectedLanguage = ref(props.lang);
const emit = defineEmits(['languageSelected']);

const languageOptions = computed(() => {
    return Object.entries(languages).map(([value, label]) => ({
        value,
        label
    }));
});

function emitSelectedLanguage() {
    emit('languageSelected', selectedLanguage.value);
}
</script>