<template>
    <div v-for="(options, field, index) in fields" :key="index" class="w-auto block mx-2 my-2">
        <div v-if="!deletedRows.includes(index)" class="w-full flex flex-row items-center">
            <div>
                <input v-model="fieldNames[index]" @change="updateFieldName(index)" class="font-bold mb-1" />
            </div>
            <div>
                <Dropdown :options="options.map(option => ({ name: option }))" optionLabel="name" v-model="selectedValues[index]" @change="emitValues" :disabled="isDisabled(index)" editable />
            </div>
            <div class="ml-2">
                <Button icon="pi pi-times" class="p-button-sm" @click="deleteRow(index)" :disabled="isDisabled(index)" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, ref, watch, reactive } from 'vue';

const props = defineProps({
    fields: Object
});

const selectedValues = ref({});
const fieldNames = reactive({});
const deletedRows = ref([]);

const emit = defineEmits(['update', 'fieldNameChanged', 'rowDeleted', 'fieldsUpdated']);


const initializeFieldNames = () => {
    Object.entries(props.fields).forEach(([field, options], index) => {
        fieldNames[index] = field;
    });
};

initializeFieldNames();

watch(selectedValues, (newVal) => {
    const transformedValues = {};
    for (const index in newVal) {
        if (Object.prototype.hasOwnProperty.call(newVal, index) && !deletedRows.value.includes(parseInt(index))) {
            const element = newVal[index];
            transformedValues[index] = {
                word: fieldNames[index],
                translation: element?.name || element
            };
        }
    }
    emitValues(transformedValues);
}, { deep: true });

const emitValues = (values) => {
    emit('update', values);
};

const updateFieldName = (index) => {
    const oldField = Object.values(fieldNames)[index];
    const newField = fieldNames[index];
    if (oldField !== newField) {
        if (selectedValues.value[index]) {
            selectedValues.value[index] = {
                name: selectedValues.value[index].name,
                word: newField
            };
        }
    }
    const transformedValues = Object.entries(selectedValues.value).reduce((acc, [idx, val]) => {
        if (val && fieldNames[idx] && !deletedRows.value.includes(parseInt(idx))) {
            acc[idx] = {
                word: fieldNames[idx],
                translation: val.name || val
            };
        }
        return acc;
    }, {});
    //console.log('transformedValues', transformedValues);
    emitValues(transformedValues);
};

const deleteRow = (index) => {
    deletedRows.value.push(index);


    const newFields = { ...props.fields };
    delete newFields[Object.keys(newFields)[index]];


    const newSelectedValues = { ...selectedValues.value };
    delete newSelectedValues[index];
    selectedValues.value = newSelectedValues;

    const transformedValues = Object.entries(newSelectedValues).reduce((acc, [idx, val]) => {
        if (val && fieldNames[idx] && !deletedRows.value.includes(parseInt(idx))) {
            acc[idx] = {
                word: fieldNames[idx],
                translation: val.name || val
            };
        }
        return acc;
    }, {});

    console.log('transformedValues', transformedValues);
    emitValues(transformedValues);
    //emit('rowDeleted', index);
    //emit('fieldsUpdated', newFields);
};

const isDisabled = (index) => {
    if (index === 0) {
        return false;
    }
    return !selectedValues.value[index - 1] || deletedRows.value.includes(index - 1);
};
</script>