<template>
    <Toast />
    <ConfirmDialog />

    <Toolbar>

        <template #start>
            <div class="inline sm:hidden">
                <div class="">
                    <SplitButton label="Neu" icon="pi pi-plus" @click="setNew" :model="items" />
                </div>
            </div>
            <div class="hidden sm:flex">
                <Button v-show="changes != 0" v-tooltip.bottom="'Änderungen zurücksetzen'" @click="reset_changes" class="mr-2 p-button-danger" icon="pi pi-refresh" />
                <Button v-show="changes > 0" @click="save_changes" v-tooltip.bottom="'Änderungen speichern'" :label="save_text" class="w-auto  p-button-success mx-2" icon="pi pi-save" />

                <Button @click="setNew" label="Neuer Kurs" class="w-auto mr-2 p-button" v-tooltip.top="'Ein neuer Datensatz erfassen'" icon="pi pi-plus" />
            </div>
        </template>


    </Toolbar>
    <Toolbar class="mt-2">
        <template #start>
            <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filter_dataset['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
            </span>
        </template>
    </Toolbar>
    <!--------------------------------------------------->
    <!------------------ DataTable ---------------------->
    <!--------------------------------------------------->
    <DataTable class="mt-3" v-model:filters="filter_dataset" :value="dataset" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20, 50, 100]" @rowReorder="onTeamRowReorder" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="{first} bis {last} von {totalRecords}" @row-dblclick="handleRowDoubleClick">
        <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
        <Column field="status" header="Status">
            <template #body="{ data, field }">
                <span class="inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/10" v-if="data[field] == 'draft'" :label="get_status(data[field])" v-tooltip.top="'Beitrag ist Publiziert'">{{ get_status(data[field]) }}</span>

                <span class="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-900 ring-1 ring-inset ring-green-600/20" v-if="data[field] == 'published'" v-tooltip.top="'Beitrag ist Publiziert'">{{ get_status(data[field]) }}</span>
            </template>
        </Column>
        <Column field="name" header="name" class=" min-w-48">
            <template #body="slotProps">
                <span class="">{{ slotProps.data.name }}</span>
            </template>
        </Column>
        <Column>
            <template #body="slotProps">
                <Button v-if="force_save == false" @click="handleEditClick(slotProps)" class="p-button-rounded p-button-text" v-tooltip.top="'Kursdaten bearbeiten'" icon="pi pi-pencil" />
                <Button class="p-button-rounded p-button-text" v-tooltip.top="'Kurs Designer öffnen'" icon="pi pi-cog" @click="openDesigner(slotProps.data)" />
                <Button v-if="force_save == false" @click="delete_dataset(slotProps.data.workflow_id)" class="p-button-rounded p-button-text" v-tooltip.top="'Kurs löschen'" icon="pi pi-trash" />
            </template>
        </Column>
    </DataTable>

    <div class="flex items-center justify-center m-16" v-show="loader">
        <LoadingSpinner size="12"></LoadingSpinner>
    </div>


    <!------------------- Dialogs ----------------------->
    <Dialog v-model:visible="editDialog" header="Datensatz bearbeiten" :modal="true" class="w-auto md:w-3/4">

        <TabView>
            <TabPanel>
                <template #header>
                    <i class="pi pi-file mr-2"></i>
                    <span class="mr-3">Einstellungen</span>
                </template>
                <!-- <div class="w-auto mx-auto">
                    <ButtonRecreateAudiofiles :workflow_id="data_workflow_id" />
                </div> -->
                <div class="w-full mx-auto">
                    <div class="flex flex-col">
                        <div class="mb-4">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="name">Titel *</label>
                            <InputText v-model="data_name" id="name" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight" required />
                        </div>
                        <div class="mb-4">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="name">Titel Kurs</label>
                            <InputText v-model="data_title_course" id="title_course" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight" required />
                        </div>
                        <div class="mb-4">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="name">Titel Student</label>
                            <InputText v-model="data_title_student" id="title_student" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight" required />
                        </div>
                        <div class="mb-4">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="name">Sprache Kurs</label>
                            <!-- <InputText v-model="data_language_course" id="language_course" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight" required /> -->
                            <LanguageDropdown @languageSelected="select_lang_course" :lang="data_language_course" />
                        </div>
                        <div class=" mb-4">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="name">Sprache Student</label>
                            <!-- <InputText v-model="data_student_language" id="student_language" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight" required /> -->

                            <LanguageDropdown @languageSelected="select_lang_student" :lang="data_student_language" />
                        </div>

                        <div class="mb-4">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="status">Status</label>
                            <Dropdown v-model="data_status" id="status" :options="status" optionLabel="label" optionValue="value" class="w-full" />
                        </div>
                    </div>


                </div>
            </TabPanel>

            <TabPanel>
                <template #header>
                    <i class="pi pi-tags mr-2"></i>
                    <span class="">Rollen</span>
                </template>
                <div class="w-auto">
                    <RoleEditor @get="get_role_data" :data="data_role_editor" />
                </div>
            </TabPanel>
        </TabView>
        <!-- Buttons -->
        <div class="flex items-center justify-between mt-4">
            <Button :disabled="!formValid_big" label="Speichern" @click="save_dataset(data_index, true), editDialog = false" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />

            <Button label="Abbrechen" @click="editDialog = false" class="w-auto mr-3 mb-1 p-button-warning" icon="pi pi-times" />

        </div>
    </Dialog>

    <!------------------ New ---------------------->
    <Dialog v-model:visible="newDialog" header="Kurs hinzufügen" :modal="true" class=" w-full mx-3 md:w-3/4">

        <div class="w-full mx-auto">
            <div class="flex flex-wrap">

                <!-- Column 1 -->
                <div class="w-full px-3">
                    <!-- Name -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="name">Name *</label>
                        <InputText v-model="data_name" :disabled="save_loader" id="name" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight " required />
                    </div>
                </div>

            </div>

            <!-- Buttons -->
            <div class="flex items-center justify-between mt-4">
                <Button :disabled="!formValid_entry" :loading="save_loader" label="Speichern" @click="new_dataset" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                <Button label="Abbrechen" @click="newDialog = false" :loading="save_loader" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
            </div>
        </div>



    </Dialog>
</template>


<script setup>
import { ref, onMounted, watch, inject, computed } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import Simplestore from '@/core/var/Simplestore';
import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue';
import { FilterMatchMode } from 'primevue/api'
import { useCustomerStore } from '@/core/store/CustomerStore'
import { useConfirm } from "primevue/useconfirm"
import { getRandomId } from '@/core/var/tools'
import RoleEditor from './Cruds/Role-Editor.vue';
import LanguageDropdown from './Components/Langcode-Dropdown.vue'
import ButtonRecreateAudiofiles from './Components/Button-RecreateAudiofiles.vue';


const CoreRouter = inject('CoreRouter');
const confirm = useConfirm()
// Data
// const data_startDate = ref(null);
// const data_endDate = ref(null);
// const data_minOrder = ref(0);
// const data_maxUsage = ref(0);
// const data_selectedProducts = ref([]);
// const data_excludedProducts = ref([]);
const products = ref([]);
const save_loader = ref(false);
const data_role_editor = ref([]);

const yesNoOptions = [
    { label: 'Ja', value: true },
    { label: 'Nein', value: false }
];


const formValid_entry = computed(() => data_name.value);

const formValid_big = computed(() => {
    const res = data_name.value
    return res
});

const toast = useToast()
const SERVICE_URL = inject('SERVICE_URL');
const dataset = ref([])
const data_name = ref(null)
const data_status = ref('draft')
const data_index = ref();
const data_language_course = ref(null)
const data_student_language = ref(null)
const data_title_course = ref(null)
const data_title_student = ref(null)

const data_workflow_id = ref(null)
const changes = ref(0)
const save_text = ref("Änderungen speichern")
const editDialog = ref(false)
const newDialog = ref(false)
const force_save = ref(false)
const loader = ref(false)
const disabled = ref()
const save_status = ref()
const recreate_audio_loader = ref(false)

const status = ref([
    { label: 'Aktiv', value: 'published' },
    { label: 'Inaktiv', value: 'draft' }
])

const filter_dataset = ref({ 'global': { value: null, matchMode: FilterMatchMode.CONTAINS } })
const slug = ref(useCustomerStore().getCustomer.customer);
const module = 'courses';

const endpoints = {
    get: `${module}/get`,
    getImagelib: `${module}/get-image-library`,
    uploadImage: `${module}/upload-image`,
    uploadLibImage: `${module}/upload-library-image`,
    getsystem: "get-system",
    getcategories: `${module}/categories`,
    gettags: `${module}/tags`,
    getusers: "get-users",
    save: `${module}/save`
}


onMounted(async () => {
    loader.value = true;
    await bootstrap();
    loader.value = false;
})


const bootstrap = async () => {
    try {
        const [dataResponse] = await Promise.all([
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/${endpoints.get}`),
        ]);
        dataset.value = dataResponse.data;

    } catch (error) {
        console.error(error);
    }
}


const items = ref([
    {
        label: save_text,
        icon: 'pi pi-refresh',
        disabled: changes.value > 0 ? false : true,
        command: () => {
            save_changes();
        }
    },
    {
        label: 'Zurücksetzen',
        icon: 'pi pi-times',
        disabled: changes.value != 0 ? false : true,
        command: () => {
            reset_changes();
        }
    },
]);

watch(changes, res => {
    items.value[0].disabled = res > 0 ? false : true;
    items.value[1].disabled = res != 0 ? false : true;
});

watch(loader, res => {
    Simplestore.spinner = res;
    disabled.value = res;
});

const reset_changes = async () => {
    try {
        const response = await axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/${endpoints.get}`);
        dataset.value = response.data;
        changes.value = 0;
        save_text.value = "Änderungen speichern";
        force_save.value = false;
    } catch (error) {
        console.error(error);
    }
};


// const recreate_audio = async () => {
//     recreate_audio_loader.value = true
//     await recreate_course_soundfiles(data_workflow_id.value)
//     recreate_audio_loader.value = false
// }

// const recreate_course_soundfiles = async workflow_id => {
//     try {
//         const response = await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/coursedesigner/recreate_audio`, { workflow_id: workflow_id }); 
//         return response.data;
//     } catch (error) {
//         console.error(error);
//     }
// }

const onTeamRowReorder = (e) => {
    dataset.value = e.value
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    force_save.value = true
    toast.add({ severity: 'success', summary: 'Reihenfolge geändert', detail: 'Die Reihenfolge wurde erfolgreich geändert', life: 3000 })
}

const get_status = (status_value) => {
    if (status_value == "published") {
        return "Aktiv"
    } else if (status_value == "draft") {
        return "Inaktiv"
    }
}

const setNew = () => {
    newDialog.value = true;
    data_name.value = ''
    data_status.value = 'draft'
    data_language_course.value = ''
    data_student_language.value = ''
    data_title_course.value = ''
    data_title_student.value = ''
    data_role_editor.value = []
}

const save_dataset = async (index) => {

    dataset.value[index].workflow_id = data_workflow_id.value;
    dataset.value[index].name = data_name.value;
    dataset.value[index].status = data_status.value;
    dataset.value[index].language_course = data_language_course.value;
    dataset.value[index].student_language = data_student_language.value;
    dataset.value[index].title_course = data_title_course.value;
    dataset.value[index].title_student = data_title_student.value;
    dataset.value[index].role_editor = data_role_editor.value;

    incrementSaves();
}

const new_dataset = async () => {

    data_workflow_id.value = getRandomId(10)

    const append = {
        "workflow_id": data_workflow_id.value,
        "name": data_name.value,
        "status": "draft",
        "language_course": data_language_course.value,
        "student_language": data_student_language.value,
        "title_course": data_title_course.value,
        "title_student": data_title_student.value,
        "role_editor": data_role_editor.value,
        "lectures": []
    }

    dataset.value.unshift(append)
    force_save.value = true
    incrementSaves();

    save_status.value = 'create'

    newDialog.value = false

}



const delete_dataset = async (id) => {

        confirm.require({
        message: 'Willst du den Kurs wirklich löschen?',
        header: 'Kurs löschen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja!',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
            const newt = dataset.value.filter(dataset => dataset.workflow_id !== id);
            dataset.value = newt;
            incrementSaves();
            data_workflow_id.value = id;
            save_status.value = 'delete'
        }
    })


}

const incrementSaves = () => {
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
}

const save_changes = async () => {

    save_loader.value = true;
    await save_course();

    if (save_status.value == 'create') {
        await create_course();
        save_loader.value = false;
    }
    if (save_status.value == 'delete') {
        await delete_course();
        save_loader.value = false;
    }
};

const handleEditClick = (slotProps) => {

    data_index.value = slotProps.index;
    data_status.value = slotProps.data.status;
    data_workflow_id.value = slotProps.data.workflow_id;
    data_name.value = slotProps.data.name;
    data_language_course.value = slotProps.data.language_course;
    data_student_language.value = slotProps.data.student_language;
    data_title_course.value = slotProps.data.title_course;
    data_title_student.value = slotProps.data.title_student;
    data_role_editor.value = slotProps.data.role_editor;

    editDialog.value = true;
}
function handleRowDoubleClick(event) {
    handleEditClick(event);
}

const openDesigner = (data) => {
    CoreRouter.push({ name: 'Kurs-Designer', params: { foo: 'bar' }, query: data })
}

const save_course = async () => {
    try {
        await axios.post(
            `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/${endpoints.save}`,
            dataset.value);

        toast.add({
            severity: "info",
            summary: "Änderungen gespeichert",
            detail: "Die Änderungen wurden erfolgreich gespeichert",
            life: 3000
        });

        changes.value = 0;
        save_text.value = "Änderungen speichern";
        force_save.value = false;
    } catch (error) {
        console.error(error);
    }
}

const create_course = async () => {
    try {
        await axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/coursedesigner/create/${data_workflow_id.value}`);
        return true;
    } catch (error) {
        console.error(error);
    }
}

const delete_course = async () => {
    try {
        await axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/coursedesigner/delete/${data_workflow_id.value}`);
        return true;
    } catch (error) {
        console.error(error);
    }
}

const get_role_data = res => {
    data_role_editor.value = res;
}

const select_lang_student = (lang) => {
    data_student_language.value = lang;
};

const select_lang_course = (lang) => {
    data_language_course.value = lang;
};
</script>
