<script setup>
import { ref, onMounted, watch, nextTick } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useAuthStore } from '@/core/store/AuthStore'
import { useCustomerStore } from '@/core/store/CustomerStore';
import '@/core/assets/primevue/neuraxis.css';

// Print Templates
import BankStatement from './print_templates/bank-statement.vue'
import RepReport from './print_templates/rep-report.vue'

const getQueryParam = key => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get(key);
}

const template = ref()
const print_param = ref()

onMounted(() =>{
    template.value = getQueryParam('template')
    print_param.value = getQueryParam('print_param')
    console.log(template.value, print_param.value)
})

const print_content = ref()
const print_window = () => {
    let printContents = print_content.value.innerHTML
    document.body.innerHTML = printContents
    window.print()
    window.close()
}
</script>

<template>
    <div class="p-2">
      <Button @click="print_window()" label="Drucken" icon="pi pi-print" />
    </div>
    <div ref="print_content" style="margin: 0 auto; margin-bottom: 0.5cm; box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);">
      <div style="background: white; display: block;">
            <BankStatement v-if="template == 'bank_statement'" :print_param="print_param" />
            <RepReport v-if="template == 'rep_report'" :print_param="print_param" />
      </div>
  </div>
</template>