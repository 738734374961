import {
    Bars3Icon,
    CalendarIcon,
    HomeIcon,
    ArrowUpIcon,
    ArrowUpOnSquareIcon,
    MagnifyingGlassCircleIcon,
    MapIcon,
    MegaphoneIcon,
    UserGroupIcon,
    XMarkIcon,
    InboxIcon,
    UsersIcon,
    CogIcon,
    DocumentIcon,
    ArrowLeftOnRectangleIcon,
    QuestionMarkCircleIcon,
    UserCircleIcon,
    ArrowDownTrayIcon,
    EnvelopeIcon,
    BellIcon,
    EnvelopeOpenIcon, MagnifyingGlassIcon, Bars3BottomLeftIcon, AcademicCapIcon, BuildingLibraryIcon
} from '@heroicons/vue/24/outline';

export default [{ name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: false, order: 0 },
{ name: 'Interessenten', href: '/dashboard/Interessenten', icon: EnvelopeIcon, current: false, order: 1 },
{ name: 'Kunden', href: '/dashboard/Kunden', icon: DocumentIcon, current: false, order: 2 },
{ name: 'Statistiken', href: '/dashboard/Statistiken', icon: CalendarIcon, current: false, order: 3 },
{ name: 'Banking', href: '/dashboard/Banking', icon: BuildingLibraryIcon, current: false, order: 4 },
{ name: 'Kurs-Creator', href: '/dashboard/Kurs-Creator', icon: AcademicCapIcon, current: false, order: 5 },
{ name: 'Course-Maker', href: '/dashboard/Course-Maker', icon: AcademicCapIcon, current: false, order: 5.1 },
{ name: 'Studenten', href: '/dashboard/Studenten', icon: UsersIcon, current: false, order: 5.2 },
{ name: 'Einstellungen', href: '/dashboard/Einstellungen', icon: CogIcon, current: false, order: 6 },
{ name: 'Logout', href: '/logout', icon: ArrowLeftOnRectangleIcon, current: false, order: 7 }];