<script setup>
import { ref, onMounted, watch, defineProps } from 'vue'
import axios from 'axios'


const print_data = ref()

onMounted( async () => {
    await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/print-rep-report", {id: props.print_param})
    .then(response => {
        console.log(response.data)
        print_data.value = response.data
    })
})


const props = defineProps({
    print_param: { type: String }
})

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const format_currency_without_prefix = (value) => {
    return Number(value).toLocaleString('de-CH', {minimumFractionDigits: 2});
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}
</script>

<template>
    <div v-if="!print_data">
        Druckdaten werden geladen...
    </div>
    <div v-if="print_data" class="col-12 formgrid grid">
        <div class="field col-12 mt-3">
            <img :src="'data:image/png;base64, '+ print_data.logo_base64" />
        </div>
        <div class="field col-12 mt-3">
            <span class="font-bold text-xl">{{print_data.name}}</span>
        </div>
        <div class="field col-12 mt-3">
            <DataTable class="mt-6 w-full" :value="print_data.report_data" :rows="100">
                <Column field="registration_date" header="Datum">
                    <template #body="slotProps">
                        {{format_swiss_date(slotProps.data.registration_date)}}
                    </template>
                </Column>
                <Column field="customer_nr" header="Kd-Nr" />
                <Column header="Kunden">
                    <template #body="slotProps">
                        {{slotProps.data.firstname}} {{slotProps.data.lastname}} ({{slotProps.data.city}})
                    </template>
                </Column>
                <Column field="product_code" header="Kurs" />
                <Column field="invoices_total" header="Preis">
                    <template #body="slotProps">
                        {{ format_currency(slotProps.data.invoices_total) }}
                    </template>
                </Column>
                <Column field="points" header="Punkte">
                    <template #body="slotProps">
                        {{ slotProps.data.points }}
                    </template>
                </Column>
                <Column field="prov_a" header="Provision A">
                    <template #body="slotProps">
                        {{ format_currency(slotProps.data.prov_a) }}
                    </template>
                </Column>
                <Column field="prov_b" header="Provision B">
                    <template #body="slotProps">
                        {{ format_currency(slotProps.data.prov_b) }}
                    </template>
                </Column>
                <ColumnGroup type="footer">
                    <Row>
                        <Column footer="Total:" :colspan="4" footerStyle="text-align:left" />
                        <Column :footer="format_currency(print_data.total_price)" />
                        <Column :footer="String(Math.round(100 * print_data.total_points) / 100)" />
                        <Column :footer="format_currency(print_data.total_prov_a)" />
                        <Column :footer="format_currency(print_data.total_prov_b)" />
                    </Row>
                </ColumnGroup>
            </DataTable>
        </div>
    </div>
</template>