<template>
    <i :class="spinning ? 'pi pi-spin pi-spinner text-primary-500 cursor-pointer mx-2 mt-1 align-text-top' : 'pi pi-refresh text-primary-500 cursor-pointer mx-2 mt-1 align-text-top'" @click="onClick"></i>
</template>

<script setup>
//import { ref, withDefaults } from 'vue';

const props = defineProps({
    spinning: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['click']);

const onClick = () => {
    emit('click');
};
</script>