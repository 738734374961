<script setup>
import { ref, defineProps, defineEmits, watch, inject } from 'vue'

import MenuButton from '@/core/components/Components/Builder-Blocks/Components/Menu-Button.vue'
import ModalButtons from '@/core/components/Components/Builder-Blocks/Components/Modal-Menue.vue'
import DataView from '@/core/components/Components/Builder-Blocks/Components/Data-View.vue'
import SentenceEditor from '../Cruds/Sentence-Editor.vue'
import { getRandomId } from '@/core/var/tools'

const CoreRouter = inject('CoreRouter');
const currentRoute = ref(CoreRouter.currentRoute);

// local variables
const editHtmlDialog = ref(false)
const BLOCK_NAME = "leseuebung_standard";
const NAME = "Leseübung";
const icon = "pi-question"

// define props
const props = defineProps({
    template_type: { type: String, required: true },
    index: { type: Number, required: false },
    data: { type: Object, required: true },
    roles: { type: Array, required: false },
    image_library: { type: Object, required: false },
    workflow_id: { type: String, required: false },
})
// define emits
const emits = defineEmits(['blockCallback'])


// fields
const text_title = ref(props.data.text_title)
const obj_role = ref(props.data.obj_role)
const json_sentence_editor = ref(props.data.json_sentence_editor)
const course_student_language = ref(currentRoute.value.query.student_language);

const template = ref(BLOCK_NAME)


// functions
const edit_block = () => {
    editHtmlDialog.value = true
}

const save_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "workflow_id": props.data.workflow_id ? props.data.workflow_id : getRandomId(8),
                "template": template.value,
                "text_title": text_title.value,
                "obj_role": obj_role.value,
                "json_sentence_editor": json_sentence_editor.value
            },
            "action": "save",
            "index": props.index
        }
    )
    editHtmlDialog.value = false
}

const delete_block = () => {
    emits('blockCallback',
        {
            "action": "delete",
            "index": props.index
        }
    )
    editHtmlDialog.value = false
}

const add_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "workflow_id": getRandomId(8),
                "template": template.value,
                "text_title": "",
                "json_sentence_editor": ""
            },
            "action": "add",
            "index": props.index
        }
    )
}

const toggleDialog = () => {
    editHtmlDialog.value = false
}

const get_sentence_data = (data) => {
    json_sentence_editor.value = data
}

</script>

<template>
    <!--------------------------------------------------->
    <!------------------ Menu View ---------------------->
    <!--------------------------------------------------->
    <MenuButton v-if="props.template_type == 'menu'" :add_block="add_block" :button="icon" :name="NAME" />

    <!--------------------------------------------------->
    <!------------------ Data View ---------------------->
    <!--------------------------------------------------->
    <DataView v-if="props.template_type == 'data'" :edit_block="edit_block" :name="NAME">
        <div class="my-6">
            Titel <b>{{ text_title }}</b><br>
            Segmente <b>{{ json_sentence_editor.length }}</b>
        </div>
    </DataView>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editHtmlDialog" :style="{ width: '92%' }" header="Block bearbeiten" :modal="true" class="p-fluid" maximizable :breakpoints="{ '1199px': '90vw', '575px': '95vw' }">
        <template #header>
            <ModalButtons :saveBlock="save_block" :deleteBlock="delete_block" :toggleDialog="toggleDialog" :disabled="text_title == ''" />
        </template>
        <div>
            <div>
                <h3 class=" text-xl mt-4">Basisdaten</h3>
                <div class="bg-gray-100 p-3 rounded-lg">
                    <div class="my-4">
                        <label for="title">Titel</label>
                        <InputText v-model="text_title" id="text_title" type="text" />
                    </div>
                    <div class="my-4">
                        <div>Sprache Student</div>
                        <div class=" font-semibold">{{ course_student_language }}</div>
                    </div>
                </div>
            </div>
            <div>
                <h3 class=" text-xl mt-4">Übungssätze</h3>
                <div class="bg-gray-100 p-3 rounded-lg">
                    <SentenceEditor @get="get_sentence_data" :data="json_sentence_editor" :roles="props.roles" />
                </div>
            </div>
    
        </div>
    </Dialog>
</template>
