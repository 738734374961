<template>
    <div v-if="unavailable">
        Upps, dieser Kurs konnten nicht gefunden werden.
    </div>
    <div v-else>
        <div class="my-5" v-if="auth.getUserRole == 0">

            <Sidebar v-model:visible="visibleAdminSidebar" header="Snappy Admin Sidebar" position="right" class=" w-96">
                <div>
                    <div>Kurs: <span class="font-semibold"> {{ name }}</span></div>
                    <div>Workflow ID: <span class="font-semibold">{{ workflow_id }}</span>
                        <!-- <span class="text-xs">course_id</span> -->
                    </div>
                    <div>Redis ID: <span class="font-semibold text-sm">{{ `sprachtechnik:courses:${workflow_id}:sort` }}</span></div>
                    <div>
                        <RecreateButton :workflow_id="workflow_id" @done="recreate_done" />
                    </div>
                    <span class=" text-xs">This function is only available for admins.</span>
                </div>
            </Sidebar>
        </div>

        <Toast />
        <ConfirmDialog />

        <TabView class="col-12" ref="tabview4" v-model:activeIndex="activeTabIndex">
            <TabPanel>
                <template #header>
                    <i class="pi pi-comment mr-2"></i>
                    <span class="mr-3">Kurs {{ text.head }}</span>
                </template>
                <Toolbar>
                    <template #start>
                        <div class="inline sm:hidden">
                            <div class="">
                                <SplitButton label="Neu" icon="pi pi-plus" @click="setNew" :model="items" />
                            </div>
                        </div>
                        <div class="hidden sm:inline">

                            <Button @click="goBack" class="w-auto mr-2 p-button" icon="pi pi-angle-left" v-tooltip.bottom="'Zurück'" />

                            <Button v-if="changes != 0" v-tooltip.bottom="'Änderungen zurücksetzen'" @click="reset_changes" class="mr-2 p-button-danger" icon="pi pi-refresh" :disabled="disabled" />

                            <Button :disabled="changes != 0 ? false : true" @click="save_changes" v-tooltip.bottom="'Speichern'" :label="save_text" class="w-auto mr-2 p-button-success" icon="pi pi-save" :loading="loader" v-if="changes > 0" />

                            <Button @click="setNew" :label="text.new" class="w-auto mr-2 p-button" v-tooltip.top="text.new" icon="pi pi-plus" :disabled="disabled" />


                        </div>
                    </template>
                    <template #end>
                        <div class="flex gap-1">
                            <Button type="button" icon="pi pi-refresh" aria-controls="overlay_menu" @click="reset_changes()" class="hidden  sm:inline-block" :disabled="disabled" />
                            <Button icon="pi pi-question-circle" @click="visibleAdminSidebar = true" v-if="auth.getUserRole == 0" />
                        </div>
                    </template>
                </Toolbar>
                <div v-if="main_data.length > 0">
                    <Toolbar class="mt-2">
                        <template #start>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filter_blogs['global'].value" placeholder="Suche" class="mr-2 w-32 md:w-auto" />
                            </span>
                            <Dropdown :showClear="true" @change="select_status_filter()" placeholder="Status" id="status_filter" v-model="filter_blog_status" :options="blog_status" optionLabel="name" optionValue="value" class="w-32 md:w-auto ml-2" />
                        </template>
                    </Toolbar>
                    <!--------------------------------------------------->
                    <!------------------ DataTable ---------------------->
                    <!--------------------------------------------------->
                    <DataTable class="mt-3" v-model:filters="filter_blogs" :value="main_data" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20, 50, 100]" editMode="cell" @cell-edit-complete="onBlogRowEdit" @rowReorder="onTextFieldRowReorder" :paginator="true"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}" :loading="loader" @row-dblclick="onRowDoubleClick">
                        <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                        <Column field="status" header="Status">
                            <template #editor="{ data, field }">
                                <Dropdown id="blog_status" v-model="data[field]" :options="blog_status" optionLabel="name" optionValue="value" class="w-auto mr-2" />
                            </template>
                            <template #body="{ data, field }">

                                <span class="inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/10" v-if="data[field] == 'draft'" :label="get_status(data[field])" v-tooltip.top="'Entwurf'">{{ get_status(data[field]) }}</span>
                                <span class="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-900 ring-1 ring-inset ring-green-600/20" v-if="data[field] == 'published'" v-tooltip.top="'Beitrag ist Publiziert'">{{ get_status(data[field]) }}</span>
                            </template>
                        </Column>
                        <Column>
                            <template #body="slotProps">
                                <div>
                                    <ChevronUpIcon v-if="slotProps.index != 0" class="h-10 w-10 text-gray-400" @click="shift_col('up', slotProps.index)" />
                                </div>
                                <div>
                                    <ChevronDownIcon v-if="slotProps.index != main_data.length - 1" class="h-10 w-10 text-gray-400" @click="shift_col('down', slotProps.index)" />
                                </div>
                            </template>
                        </Column>

                        <!-- <Column field="header_image" class="w-28">
                            <template #body="slotProps">
                                <img v-if="slotProps.data.header_image" :src="slotProps.data.header_image" width="32" class="shadow-2 mr-2 align-middle w-32 h-16 object-cover rounded-md shadow-sm" />
                                <ImagePlaceholder class="h-16 w-16" v-else />
                            </template>
                        </Column> -->
                        <Column field="title" header="Titel">
                            <template #body="{ data, field }">
                                <span>{{ data[field] }}</span>
                            </template>
                        </Column>
                        <Column field="lectures" header="Übungen" sortable>
                            <template #body="{ data, field }">
                                <span v-if="data[field]">{{ data[field].length }}</span>
                                <span v-else class=" text-orange-500">0</span>
                            </template>
                        </Column>
                        <!-- <Column field="dm" header="Modifikation" sortable>
                        <template #body="slotProps">
                            <span class="text-xs">{{ swiss_date_time(slotProps.data.dm) }}</span>
                        </template>
                    </Column>
                    <Column field="min_price" header="Min Preis" sortable>
                        <template #body="slotProps">
                            <span class="" v-if="slotProps.data.min_price">{{ formatToSwissCurrency(slotProps.data.min_price) }}</span>
                            <span v-else class=" text-red-500">Preis fehlt</span>
                        </template>
                    </Column>
                    <Column field="variants" header="Varianten">
                        <template #body="{ data }">
                            <span>{{ data['articles'].length }}</span>
                        </template>
                    </Column> -->
                        <Column style="width: 250px;">
                            <template #body="slotProps">
                                <Button v-if="slotProps.data.workflow_id && changes == 0" @click="edit_dataset(slotProps.data.redis_key)" v-tooltip.top="'Lektion editieren'" class="p-button-rounded p-button-text" icon="pi pi-pencil" />
                                <Button v-if="slotProps.data.workflow_id && changes == 0" @click="copy_dataset(slotProps.data.redis_key)" v-tooltip.top="'Lektion kopieren'" class="p-button-rounded p-button-text" icon="pi pi-copy" />
                                <Button v-if="slotProps.data.workflow_id && changes == 0" @click="delete_dataset(slotProps.data.redis_key)" v-tooltip.top="'Lektion löschen'" class="p-button-rounded p-button-text" icon="pi pi-trash" />
                            </template>
                        </Column>
                    </DataTable>

                    <div class="flex items-center justify-center m-16" v-show="loader">
                        <LoadingSpinner size="12"></LoadingSpinner>
                    </div>
                </div>
                <div v-else class="my-10">
                    <Message>Noch keine Lektionen vorhanden.</Message>
                </div>

            </TabPanel>
            <TabPanel v-for="(editor, key) in editing" :key="key">
                <template #header>
                    <i class="pi pi-pencil mr-2"></i>
                    <span class="mr-3">{{ editor.name }}</span>
                </template>
                <Editor :key="editorRefreshKey" :redis_key="editor.redis_key" @editor-callback="editor_callback" />
            </TabPanel>
        </TabView>

        <!--------------------------------------------------->
        <!------------------- Dialogs ----------------------->
        <!--------------------------------------------------->
        <Dialog v-model:visible="newDialog" :style="{ width: '800px' }" :header="text.head" :modal="true" class="p-fluid">
            <div class="">
                <div class="mt-3">
                    <span class="p-float-label">
                        <InputText id="new_blog_title" v-model="new_blog_title" class="w-full" />
                        <label for="new_blog_title">Name</label>
                    </span>
                </div>

                <div class="mt-3">
                    <Button @click="create_dataset()" :label="text.new" class="p-button-success" />
                </div>
            </div>
        </Dialog>
    </div>
</template>
<script setup>
import { ref, inject, watch, onMounted } from 'vue';
import Sidebar from 'primevue/sidebar';
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/vue/20/solid';
import { FilterMatchMode } from 'primevue/api'
import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue';
import { useConfirm } from "primevue/useconfirm"
import { useAuthStore } from '@/core/store/AuthStore';
import { useCustomerStore } from '@/core/store/CustomerStore';
import { slugify, swiss_date, swiss_date_time, getRandomId, formatToSwissCurrency, checkDuplicate } from '@/core/var/tools'
import Simplestore from '@/core/var/Simplestore';
import Editor from './Course-Designer-Data-Editor.vue'
import RecreateButton from './Components/Button-RecreateAudiofiles.vue';

//import ImagePlaceholder from '@/core/components/UI/Main-Image-Placeholder.vue';
const channel = ref(useCustomerStore().getCustomer.customer);
const CoreRouter = inject('CoreRouter');
const auth = useAuthStore();
const MODULE = 'coursedesigner';


const main_data = ref([])
const save_text = ref("Speichern")
const SERVICE_URL = inject('SERVICE_URL');
const changes = ref(0)
const blog_status = ref([])
const editing = ref([])
const unavailable = ref(false)
const visibleAdminSidebar = ref(false)
const new_blog_title = ref("")
const new_blog_category = ref("")

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
const filter_blogs = ref({ 'global': { value: null, matchMode: FilterMatchMode.CONTAINS } })
const filter_blog_status = ref()
const newDialog = ref(false)
const activeTabIndex = ref(0)
const disabled = ref(false)
const currentRoute = ref(CoreRouter.currentRoute);

const workflow_id = ref(currentRoute.value.query.workflow_id);
const name = ref(currentRoute.value.query.name);

const goBack = () => {
    CoreRouter.go(-1);
}


const text = {
    head: `${name.value}`,
    speichern: "Speichern",
    new: 'Neue Lektion',
    eintrag: 'Der Eintrag muss zuerst gespeichert werden!',
    created: 'Lektion wurde erstellt',
    success: 'Neue Lektion wurde erfolgreich erstellt',
    delmsg: 'Bist du sicher, dass du diese Lektion löschen möchtest?',
    copied: "Datensatz wurde kopiert",
    exist: 'Ein Lektion mit diesem Titel existiert bereits'
}


const endpoints = {
    get: `${MODULE}/get`,
    getsystem: 'get-system',
    getarticles: `articles/get`,
    getcategories: `${MODULE}/categories/get`,
    gettags: `${MODULE}/tags`,
    getusers: 'get-users',
    save: `${MODULE}/save`
}

const recreate_done = data => {
    //console.log('recreate', data)
}
const items = ref([
    {
        label: save_text,
        icon: 'pi pi-refresh',
        disabled: changes.value > 0 ? false : true,
        command: () => {
            save_changes();
            //toast.add({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
        }
    },
    {
        label: 'Zurücksetzen',
        icon: 'pi pi-times',
        disabled: changes.value != 0 ? false : true,
        command: () => {
            reset_changes();
            //toast.add({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
        }
    }
]);


watch(changes, res => {
    items.value[0].disabled = res > 0 ? false : true;
    items.value[1].disabled = res != 0 ? false : true;
});


const setNew = () => {
    newDialog.value = true;
    new_blog_title.value = '';
    new_blog_category.value = '';
}


onMounted(async () => {
    loader.value = true;
    await bootstrap()
    loader.value = false;
})


watch(loader, res => {
    Simplestore.spinner = res;
    disabled.value = res;
});


const bootstrap = async () => {
    try {
        const [dataResponse, systemResponse] = await Promise.all([
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.get}/${workflow_id.value}`),
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/dashboard/${endpoints.getsystem}`),
            axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/courses/post/${workflow_id.value}`)
        ]);
        unavailable.value = dataResponse.data.message == 'unavailable' ? true : false;
        main_data.value = dataResponse.data == null ? [] : dataResponse.data;
        blog_status.value = systemResponse.data.blog_status;
        
    } catch (error) {
        console.error(error);
    }
};


const onTextFieldRowReorder = (e) => {
    main_data.value = e.value
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    toast.add({ severity: 'success', summary: 'Reihenfolge geändert', detail: 'Die Reihenfolge wurde erfolgreich geändert', life: 3000 })
}


const onBlogRowEdit = (e) => {
    if (main_data.value[e.index]["redis_key"] != "") {
        if (main_data.value[e.index][e.field] != e.newValue) {
            // console.log("Blog " + main_data.value[e.index].redis_key + " changed: " + e.field + " from " + main_data.value[e.index][e.field] + " to " + e.newValue)
            main_data.value[e.index][e.field] = e.newValue
            changes.value = changes.value + 1
            save_text.value = "Speichern (" + changes.value + ")"
            toast.add({ severity: 'success', summary: 'Änderungen gespeichert', detail: 'Die Änderungen wurden erfolgreich gespeichert', life: 3000 })
        }
    }
    else {
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'Der Eintrag muss zuerst gespeichert werden!', life: 3000 })
    }
}


const reset_changes = () => {

    confirm.require({
        message: 'Wollen Sie wirklich alle Änderungen verwerfen?',
        header: 'Änderungen verwerfen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Änderungen verwerfen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
            await axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.get}/${workflow_id.value}`)
                .then(response => {
                    main_data.value = response.data
                    incrementSaves();
                })
        }
    })
}


const save_changes = async () => {


    confirm.require({
        message: 'Wollen Sie wirklich alle Änderungen speichern?',
        header: 'Änderungen speichern',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
            loader.value = true;

            await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.save}/${workflow_id.value}`, main_data.value)
                .then(response => {
                    main_data.value = response.data
                    toast.add({ severity: 'success', summary: 'Erfolgreich gespeichert', detail: 'Änderungen wurden erfolgreich gespeichert', life: 3000 })
                    changes.value = 0
                    loader.value = false
                })
            loader.value = false
        }
    })
}

const incrementSaves = () => {
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
}

const create_dataset = async () => {
    if (!new_blog_title.value) {
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'Bitte geben Sie einen Titel ein', life: 3000 })
        return
    }

    // const checkTitle = await checkDuplicate(new_blog_title.value, false, 'swisswool/products/check-title')

    // if (checkTitle.msg == true) {
    //     toast.add({ severity: 'error', summary: 'Fehler', detail: 'Titel existiert bereits', life: 3000 })
    //     loader.value = false;
    //     return;
    // }

    if (check_if_slug_exists(new_blog_title.value)) {
        toast.add({ severity: 'error', summary: 'Fehler', detail: text.exist, life: 3000 })
        return
    }

    const slug = slugify(new_blog_title.value);

    const redis_key = setRedisKey(slug, MODULE)

    let new_blog = {
        "workflow_id": getRandomId(6),
        "course_id": workflow_id.value,
        "title": new_blog_title.value,
        "slug": slug,
        "redis_key": redis_key,
        "status": "draft",
        "status_props": {
            "key": "published",
            "value": "Publiziert"
        },
        "user": auth.userdata.user_email,
        "header_image": "",
        "dc": Date.now(),
        "dm": Date.now(),
        "changes": [
            {
                "user": auth.userdata.user_email,
                "date": Date.now(),
                "message": "Datensatz wurde erstellt"
            }
        ],
        "content": [],
        "text_content": "",
        "image_library": []
    }
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    if (main_data.value !== null) {
        main_data.value.unshift(new_blog)
    }
    toast.add({ severity: 'success', summary: text.new, detail: text.success, life: 3000 })
    newDialog.value = false
}

const setRedisKey = (slug, mdle) => {
    const redis_key = `${channel.value}:courses:${workflow_id.value}:${slug}`
    return redis_key
}

const delete_dataset = (redis_key) => {
    confirm.require({
        message: text.delmsg,
        header: 'Lektion löschen',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Lektion löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            for (let key in main_data.value) {
                if (main_data.value[key].redis_key == redis_key) {
                    main_data.value.splice(key, 1)
                    changes.value = changes.value + 1
                    save_text.value = "Speichern (" + changes.value + ")"
                    toast.add({ severity: 'success', summary: 'Gelöscht', life: 3000 })
                }
            }
        }
    })
}


const copy_dataset = (redis_key) => {
    for (let key in main_data.value) {
        if (main_data.value[key].redis_key == redis_key) {

            let new_blog = JSON.parse(JSON.stringify(main_data.value[key]))
            new_blog.workflow_id = '' // important to keep it empty!
            new_blog.slug = `${new_blog.slug}-copy-${getRandomId(4)}`
            new_blog.title = new_blog.title + " (Kopie)"
            new_blog.redis_key = setRedisKey(new_blog.slug, MODULE)
            new_blog.dc = Date.now()
            new_blog.dm = Date.now()
            new_blog.publish_date = swiss_date(Date.now())
            new_blog.user = auth.userdata.user_email
            new_blog.image_library = []
            new_blog.content = []
            new_blog.changes = [
                {
                    "user": auth.userdata.user_email,
                    "date": swiss_date(Date.now()),
                    "message": text.copied
                }
            ]
            new_blog.status = "draft"
            main_data.value.unshift(new_blog)
            changes.value = changes.value + 1
            save_text.value = "Speichern (" + changes.value + ")"
            toast.add({ severity: 'success', summary: 'Lektion kopiert', detail: 'Der Lektion wurde erfolgreich kopiert', life: 3000 })
            break

        }
    }
}


const check_if_slug_exists = (title) => {

    if (main_data.value !== null) {
        let exists = false
        let slug = slugify(title)
        main_data.value.forEach(blog => {
            if (blog.slug == slug) {
                exists = true
            }
        })
        return exists
    }
}

const editor_callback = (e) => {
    if (e.action == "close_tab") {
        for (let key in editing.value) {
            if (editing.value[key]["redis_key"] == e.redis_key) {
                editing.value.splice(key, 1)
                activeTabIndex.value = editing.value.length
            }
        }
    }
}


const select_status_filter = () => {
    filter_blogs.value['global'].value = filter_blog_status.value
}


watch([activeTabIndex], () => {
    if (activeTabIndex.value == 0) {
        bootstrap()
    }
})


const get_status = (status) => {

    for (let i = 0; i < blog_status.value.length; i++) {
        if (blog_status.value[i].value == status) {
            if (status == "published") {
                return "Publiziert"
            }
            if (status == "draft") {
                return "Entwurf"
            }
        }
    }
}


const shift_col = (direction, index) => {

    if (direction == 'up') {
        if (index != 0) {
            let temp = main_data.value[index]
            main_data.value[index] = main_data.value[index - 1]
            main_data.value[index - 1] = temp
        }
    } else {
        if (index != main_data.value.length - 1) {
            let temp = main_data.value[index]
            main_data.value[index] = main_data.value[index + 1]
            main_data.value[index + 1] = temp
        }
    }

    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    toast.add({ severity: 'success', summary: 'Reihenfolge geändert', detail: 'Die Reihenfolge wurde erfolgreich geändert', life: 3000 })
}


const edit_dataset = (redis_key) => {
    for (let key in main_data.value) {
        if (main_data.value[key].redis_key == redis_key) {
            let found = editing.value.some((el) => el.redis_key === redis_key);
            if (!found) {
                let name = ""
                if (main_data.value[key].title.length > 25) {
                    name = main_data.value[key].title.substring(0, 25) + "..."
                } else {
                    name = main_data.value[key].title
                }
                editing.value.push({
                    "redis_key": main_data.value[key].redis_key,
                    "name": name
                })
                activeTabIndex.value = editing.value.length
            }
        }
    }
}


const onRowDoubleClick = (rowData) => {
    if (changes.value == 0)
        edit_dataset(rowData.data.redis_key)
}


</script>