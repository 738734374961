<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'

/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

const filter_reports = ref({global: {value: '', matchMode: FilterMatchMode.CONTAINS}})

const reports = ref([])
const report_data = ref([])
const report_type = ref('')
const report_id = ref()

// Vertreterabrechnung
const edit_vertreter_abrechnung_dialog = ref(false)

const edit_report = (e, rtype) => {
    if (rtype == "Vertreterabrechnung") {
        report_type.value = rtype
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/print-rep-report", {id: e.id})
        .then(response => {
            console.log(response.data)
            report_data.value = response.data.report_data
            report_id.value = e.id
            edit_vertreter_abrechnung_dialog.value = true
        })
    }
}

const edit_report_row = (e) => {
    console.log(e)
    // check if field is a number
    if (e.field == 'invoices_total' || e.field == 'points' || e.field == 'prov_a' || e.field == 'prov_b') {
        report_data.value[e.index][e.field] = parseFloat(e.newValue)
    }
    else{
        report_data.value[e.index][e.field] = e.newValue
    }
    
}

const save_report = () => {
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/save-report', {id: report_id.value, type: report_type.value, report_data: report_data.value})
    .then(response => {
        toast.add({severity:'success', summary:'Erfolg', detail:'Report wurde gespeichert.'})
        edit_vertreter_abrechnung_dialog.value = false
    })
}

onMounted(() => {
    get_reports()
})

const get_reports = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/get-reports')
    .then(response => {
        reports.value = response.data
    })
}

const delete_report = (id) => {
    confirm.require({
        message: 'Möchten Sie den Report wirklich neu generieren?',
        header: 'Bestätigen',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, neu generieren',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/delete-report', {id: id})
            .then(response => {
                toast.add({severity:'success', summary:'Erfolg', detail:'Report wurde neu generiert.'})
                get_reports()
            })
        }
    });
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}
const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const print_rep_report = (id) => {
    const newWindow = window.open('https://sprachtechnik.snpy.ch/print?template=rep_report&print_param='+id, '_blank', 'width=595,height=842');
    if (newWindow) {
    newWindow.focus();
    }
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toolbar>
        <template #start>
            <span class="p-input-icon-left mr-3">
                <i class="pi pi-search" />
                <InputText v-model="filter_reports['global'].value" placeholder="Suche" style="width: 400px" />
            </span>
        </template>
    </Toolbar>
    <div rounded class="overflow-hidden shadow-4 rounded border-200 mt-4">
        <DataTable v-model:filters="filter_reports" :value="reports" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
            <Column field="name" header="Report" sortable>
                <template #body="slotProps">
                    <Avatar icon="pi pi-file" class="mr-2 bg-primary text-white" shape="circle" />
                    <span class="font-bold">{{slotProps.data.name}}</span>
                </template>
            </Column>
            <Column field="type" header="Typ" sortable style="width: 20%">
                <template #body="slotProps">
                    <Badge :value="slotProps.data.type"></Badge>
                </template>
            </Column>
            <Column field="id" header="Report" style="width: 10%">
                <template #body="slotProps">
                    <Button icon="pi pi-print" class="mr-2 p-button-rounded" @click="print_rep_report(slotProps.data.id)" v-tooltip.top="'Report drucken'" />
                </template>
            </Column>
            <Column field="date" header="Datum" style="width: 10%">
                <template #body="slotProps">
                    {{format_swiss_date(slotProps.data.date)}}
                </template>
            </Column>
            <Column style="width: 15%">
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="mr-2 p-button-rounded p-button-text" @click="edit_report(slotProps.data, 'Vertreterabrechnung')" v-tooltip.top="'Report editieren'" />
                    <Button icon="pi pi-refresh" class="mr-2 p-button-rounded p-button-text p-button-danger" @click="delete_report(slotProps.data.id)" v-tooltip.top="'Report neu generieren'" />
                </template>
            </Column>
        </DataTable>
    </div>
    <!-- Dialog: Edit Vertreterabrechnung -->
    <Dialog v-model:visible="edit_vertreter_abrechnung_dialog" :style="{width: '90%'}" header="Vertreterabrechnung editieren" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <DataTable :value="report_data" :rows="1000" responsiveLayout="scroll" editMode="cell" @cell-edit-complete="edit_report_row" :rowHover="true">
                    <Column field="registration_date" header="Datum">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            {{data[field]}}
                        </template>
                    </Column>
                    <Column field="customer_nr" header="Kd-Nr">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            {{data[field]}}
                        </template>
                    </Column>
                    <Column field="firstname" header="Vorname">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            {{data[field]}}
                        </template>
                    </Column>
                    <Column field="lastname" header="Nachname">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            {{data[field]}}
                        </template>
                    </Column>
                    <Column field="product_code" header="Kurs">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            {{data[field]}}
                        </template>
                    </Column>
                    <Column field="invoices_total" header="Preis">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            {{data[field]}}
                        </template>
                    </Column>
                    <Column field="points" header="Punkte">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            {{data[field]}}
                        </template>
                    </Column>
                    <Column field="prov_a" header="Provision A">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            {{data[field]}}
                        </template>
                    </Column>
                    <Column field="prov_b" header="Provision B">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            {{data[field]}}
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="field col-12 mt-3">
                <Button label="Speichern" icon="pi pi-save" class="w-auto" @click="save_report" />
            </div>
        </div>
    </Dialog>
</template>