<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'

/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters
const filter_products = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const products = ref()
const metaproducts = ref()
const editProductDialog = ref(false)
const product_price = ref()
const product_points = ref()
const product_prov_a = ref()
const product_prov_b = ref()
const product_id = ref()


const get_base_data = () => {
    // Get Data
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/get-all-bexio-products')
    .then(response => {
        products.value = response.data
    })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/get-meta-products')
    .then(response => {
        metaproducts.value = response.data
    })
}
const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}
onMounted(() => {
    get_base_data()
})

const edit_product = (id) => {
    product_id.value = id
    product_price.value = parseFloat(get_product_price(id))
    product_points.value = get_metaproduct_points(id)
    product_prov_a.value = get_metaproduct_prov_a(id)
    product_prov_b.value = get_metaproduct_prov_b(id)
    editProductDialog.value = true
}

const save_product = () => {
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/update-metaproduct', {
        id: product_id.value,
        price: product_price.value,
        points: product_points.value,
        prov_a: product_prov_a.value,
        prov_b: product_prov_b.value
    })
    .then(response => {
        toast.add({severity:'success', summary: 'Produkt aktualisiert', detail: 'Produkt wurde aktualisiert', life: 3000})
        editProductDialog.value = false
        get_base_data()
    })
}

const get_metaproduct_points = (id) => {
    let metaproduct_points = 0
    for (let key in metaproducts.value)
        if(metaproducts.value[key].id == id){
            metaproduct_points = metaproducts.value[key]["points"]
        }
    return metaproduct_points
}

const get_metaproduct_prov_a = (id) => {
    let metaproduct_prov_a = 0
    for (let key in metaproducts.value)
        if(metaproducts.value[key].id == id){
            metaproduct_prov_a = metaproducts.value[key]["prov_a"]
        }
    return metaproduct_prov_a
}

const get_metaproduct_prov_b = (id) => {
    let metaproduct_prov_b = 0
    for (let key in metaproducts.value)
        if(metaproducts.value[key].id == id){
            metaproduct_prov_b = metaproducts.value[key]["prov_b"]
        }
    return metaproduct_prov_b
}

const get_product_price = (id) => {
    let product_price = 0
    for (let key in products.value)
        if(products.value[key].id == id){
            product_price = products.value[key]["sale_price"]
        }
    return product_price
}
</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toolbar>
        <template #start>
            <span class="p-input-icon-left mr-3">
                <i class="pi pi-search" />
                <InputText v-model="filter_products['global'].value" placeholder="Suche" style="width: 400px" />
            </span>
        </template>
    </Toolbar>
    <div rounded class="overflow-hidden shadow-4 rounded border-200 mt-4">
        <DataTable v-model:filters="filter_products" :value="products" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
            <Column field="intern_name" header="Produkt" sortable>
                <template #body="slotProps">
                    <Avatar icon="pi pi-box" class="mr-2" style="background-color:#2196F3; color: #ffffff" shape="circle" />
                    {{ slotProps.data.intern_name }} ({{ slotProps.data.intern_code }})
                </template>
            </Column>
            <Column field="sale_price" header="Preis" sortable>
                <template #body="slotProps">
                    {{ format_currency(slotProps.data.sale_price) }}
                </template>
            </Column>
            <Column field="id" header="Prov A">
                <template #body="slotProps">
                    {{ format_currency(get_metaproduct_prov_a(slotProps.data.id)) }}
                </template>
            </Column>
            <Column field="id" header="Prov B">
                <template #body="slotProps">
                    {{ format_currency(get_metaproduct_prov_b(slotProps.data.id)) }}
                </template>
            </Column>
            <Column field="id" header="Punkte">
                <template #body="slotProps">
                    {{ get_metaproduct_points(slotProps.data.id) }}
                </template>
            </Column>
            <Column field="intern_code" hidden></Column>
            <Column>
                <template #body="slotProps">
                    <Button v-tooltip.top="'Produkt editieren'" class="p-button-rounded p-button-text" icon="pi pi-pencil" @click="edit_product(slotProps.data.id)" />
                </template>
            </Column>
        </DataTable>
    </div>
    <!--------------------------------------------------->
    <!------------------ Create Lead -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editProductDialog" :style="{width: '650px'}" header="Produkt editieren" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputNumber id="sale_price" mode="currency" currency="CHF" locale="de-CH" v-model="product_price" />
                    <label for="sale_price">Preis</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputNumber id="prov_a" mode="currency" currency="CHF" locale="de-CH" v-model="product_prov_a" />
                    <label for="prov_a">Provision A</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputNumber id="prov_b" mode="currency" currency="CHF" locale="de-CH" v-model="product_prov_b" />
                    <label for="prov_b">Provision B</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputNumber id="points" :minFractionDigits="2" :maxFractionDigits="5" v-model="product_points" />
                    <label for="points">Punkte</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button label="Speichern" @click="save_product()" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                    <Button label="Abbrechen" @click="editProductDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>
</template>