<template>
    <div>
        <div class="flex gap-2 my-4">
            <Button icon="pi pi-plus" @click="showDialog('add')" />
            <!-- <Button icon="pi pi-upload" @click="emitItems" /> -->
        </div>

        <DataTable :value="items" paginator :rows="50" tableStyle="min-width: 100%" rowReorder @rowReorder="onRowReorder">
            <Column rowReorder style="width:3em"></Column>

            <Column field="name" header="Name" sortable></Column>
            <Column field="template_id" header="Template" sortable></Column>
            <Column header="Rolle">
                <template #body="slotProps">
                    <div class="font-medium">{{ slotProps.data.role.name }}</div>
                    <div class="text-sm">{{ languageNames[slotProps.data.role.language.languageCodes[0]] }}, {{ slotProps.data.role.language.ssmlGender }}</div>
                </template>
            </Column>
            <Column field="sentence" header="Übungssatz" sortable></Column>
            <Column field="sentence_tl" header="Übersetzung"></Column>
            <Column>
                <template #body="slotProps">
                    <div class="flex space-x-2">
                        <AudioDemoButton :data="slotProps.data" :func="audio_button2" />
                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-success" @click="showDialog('edit', slotProps.data)" />
                        <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="deleteItem(slotProps.data)" />
                    </div>
                </template>
            </Column>
        </DataTable>


        <Dialog v-model:visible="dialogVisible" :header="dialogHeader" :style="{ width: '70%' }">

            <div class="w-full">
                <div class="my-4">
                    <label for="name">Name</label>
                    <InputText id="name" v-model="item.name" class=" w-full" />
                </div>
                <div class="my-4">
                    <div for="name">Sprache Student</div>
                    <div class=" font-semibold">{{ course_student_language }}</div>
                </div>
                <div class="my-4">
                    <label for="role">Rolle</label>
                    <div>
                        <Dropdown v-model="item.role" :options="props.roles" optionLabel="name" id="role" placeholder="Wählen Sie eine Rolle" class=" w-full" />
                    </div>
                    <span v-if="item.role.language" class="m-2 text-sm block">{{ item.role.language.name }}, {{ item.role.language.ssmlGender }}</span>
                </div>
                <div class="my-4">
                    <label for="sentence">Übungssatz</label>
                    <InputText v-model="item.sentence" id="sentence" type="text" class=" w-full" />
                </div>

                <div v-if="item.sentence_tl.length > 0">
                    <div class="my-4">
                        <label for="satz">Wort für Wort Übersetzung</label>
                        <MicroButton :spinning="spinning" @click="get_translation" />
                        <div class="min-h-10 my-1">
                            <transition-group name="fade" tag="div" class="inline">
                                <span v-for="(word, index) in getBadges()" :key="index" ref="badges" class="inline-flex items-center rounded-lg bg-primary-500 px-3 py-2 text-sm font-medium text-white mr-1 my-1">
                                    {{ word }}
                                </span>
                            </transition-group>
                        </div>
                        <div class="min-h-10 my-1" v-if="renderKey">
                            <SentenceDropdowns :fields="getDropdowns()" @update="getDropdownValues" :key="renderKey" />
                        </div>
                        <div class="my-4">
                            <label for="sentence_tl">Übersetzung</label>
                            <InputText v-model="item.sentence_tl" id="sentence_tl" type="text" class=" w-full" />
                        </div>
                    </div>
                    <div class="flex space-x-2">
                        <Button label="Speichern" @click="saveItem" :disabled="spinning_tts" :loading="spinning_tts" />
                        <div v-if="spinning_tts" class="flex items-center">Audio wird generiert, bitte warten...</div>
                    </div>

                    <div>
                        <h3 class=" text-xl mt-4">Audio</h3>
                        <div class="bg-gray-100 p-3 rounded-lg">
                            <div class="flex items-start space-x-2">

                                <div class=" w-1/12">
                                    <AudioDemoButton :data="item" :func="audio_button2" />
                                </div>

                                <div class=" w-10/12">

                                    <div class="my-2 flex flex-row">
                                        <div class="w-2/12 text-sm">Audio-Speed</div>
                                        <div class="w-10/12 mt-2">
                                            <Slider v-model="audio_speed" class=" w-full" :disabled="spinning_tts" />
                                        </div>
                                    </div>

                                </div>

                                <div class="w-1/12">
                                    <MicroButton :spinning="spinning_tts" @click="refresh_audio" />
                                </div>

                            </div>

                            <div class="flex items-start my-6" v-if="item.json_transcript">

                                <div class="w-1/4">
                                    <span class=" text-xl font-medium">Zeitstempel</span>
                                </div>
                                <div class="w-3/4" v-if="!spinning_tts">

                                    <div class="flex flex-col">
                                        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                                    <table class="min-w-full divide-y divide-gray-200">
                                                        <thead class="bg-gray-50">
                                                            <tr>
                                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                    Wort
                                                                </th>
                                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                    Start Zeit
                                                                </th>
                                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                    End Zeit
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="bg-white divide-y divide-gray-200">
                                                            <tr v-for="(word, index) in item.json_transcript.alternatives[0].words" :key="index">
                                                                <td class="px-6 py-4 whitespace-nowrap">

                                                                    <span class="inline-flex items-center rounded-lg bg-primary-500 px-3 py-2 text-sm font-medium text-white mr-1">
                                                                        {{ word.word }}
                                                                    </span>
                                                                </td>
                                                                <td class="px-6 py-4 whitespace-nowrap">
                                                                    <div class="text-sm text-gray-500">{{ word.startTime }}</div>
                                                                </td>
                                                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                    {{ word.endTime }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>Neuberechnung läuft...</div>
                            </div>
                            <input v-model="item.json_transcript" id="json_transcript" type="hidden" class="w-auto" />

                            <div class="flex items-start my-6" v-if="item.text_audiofile">
                                <div class="w-1/4">
                                    <span class=" text-xl font-medium">Audiofile</span>
                                </div>
                                <div v-if="!spinning_tts"><a :href="item.text_audiofile" target="_blank">{{ item.text_audiofile }}</a>
                                </div>
                                <div v-else>Neuberechnung läuft...</div>
                            </div>
                            <input v-model="item.text_audiofile" id="text_audiofile" type="hidden" class="w-auto" />

                        </div>
                    </div>
                </div>
                <div class="my-4 w-40" v-else>
                    <Button label="Übersetzung" :loading="spinning" :disabled="item.sentence.length <= 0" icon="pi pi-language" size="small" @click="get_translation" class="" />
                </div>

            </div>
        </Dialog>
    </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, onMounted, watchEffect, inject } from 'vue';
import axios from 'axios'
import { getRandomId } from '@/core/var/tools'
import MicroButton from '@/core/components/UI/Micro-Button.vue'
import SentenceDropdowns from '../Components/Sentence-Dropdowns.vue'
import AudioDemoButton from '../Components/AudioDemo-Button.vue';
import Slider from 'primevue/slider';
import languageNames from '../languages.json';
import { gsap } from 'gsap';

const CoreRouter = inject('CoreRouter');
const currentRoute = ref(CoreRouter.currentRoute);

const props = defineProps(['data', 'roles'])
const items = ref(props.data);

const item = ref({ name: '', role: '' });
const dialogVisible = ref(false);
const dialogHeader = ref('');
const emit = defineEmits(['get']);
const spinning = ref(false)
const spinning_tts = ref(false)
const showBadges = ref(true);
const badges = ref(null);

const firstEntries = ref({});
const allEntries = ref({});
const renderKey = ref(0);

const audio_speed = ref(80);

// const json_transcript = ref(props.data.json_transcript)
// const text_audiofile = ref(props.data.text_audiofile)

const course_student_language = ref(currentRoute.value.query.student_language);

//const sentences = ref([])

onMounted(() => {
    items.value = props.data ? props.data : []
});

watchEffect(() => {
    items.value = props.data ? props.data : []
});

const showDialog = (type, data = { name: '', role: '', sentence: '', sentence_tl: '', json_transcript: '', text_audiofile: '', sentences: [] }) => {
    renderKey.value = null;
    item.value = null;
    item.value = { ...data };
    dialogVisible.value = true;
    dialogHeader.value = type === 'add' ? 'Neu' : 'Bearbeiten';
};

const saveItem = () => {
    if (item.value.name) {
        let newItem = {};

        if (!item.value.workflow_id) {
            const generateWorkflowId = () => {
                return getRandomId(6);
            };

            let newWorkflowId;
            do {
                newWorkflowId = generateWorkflowId();
            } while (items.value.some(item => item.workflow_id === newWorkflowId));

            newItem = { workflow_id: newWorkflowId, ...item.value };
        } else {
            newItem = { ...item.value };
        }

        if (item.value.workflow_id) {
            const index = items.value.findIndex(i => i.workflow_id === item.value.workflow_id);
            if (index !== -1) {
                items.value[index] = newItem;
            }
        } else {
            items.value.push(newItem);
        }
        dialogVisible.value = false;
    }
    emitItems();
};
const deleteItem = (data) => {
    const index = items.value.findIndex(i => i.id === data.id);
    if (index !== -1) {
        items.value.splice(index, 1);
    }
    emitItems()
};

const onRowReorder = (event) => {
    const { value } = items;
    const draggedItem = value[event.dragIndex];
    value.splice(event.dragIndex, 1);
    value.splice(event.dropIndex, 0, draggedItem);
    items.value = [...value];
    emitItems()
};

const emitItems = () => {
    emit('get', items.value);
};

const get_translation = async () => {
    spinning.value = true
    showBadges.value = false

    gsap.set(badges.value, { opacity: 0 })
    try {

        const postData = {
            text_sentence: item.value.sentence,
            role: item.value.role,
            student_language: course_student_language.value
        };

        const [translationResponse] = await Promise.all([
            axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/sprachtechnik/coursedesigner/get_word_translation`, postData),
        ]);
        item.value.sentence_tl = ''
        allEntries.value = {}
        firstEntries.value = {}


        allEntries.value = JSON.parse(translationResponse.data)

        // sentences
        const inputJSON = JSON.parse(translationResponse.data);

        const outputList = [];

        for (const key in inputJSON) {
            outputList.push({
                word: key,
                translation: inputJSON[key][0]
            });
        }
        item.value.sentences = outputList
        //console.log(outputList);
        //console.log(allEntries.value, translationResponse.data)

        // sentence_tl
        const obj = JSON.parse(translationResponse.data)

        for (let key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                firstEntries.value[key] = obj[key][0];
            }
        }

        item.value.sentence_tl = Object.values(firstEntries.value).join(' ');

        gsap.to(badges.value, {
            opacity: 1,
            stagger: 0.1,
            duration: .1,
            ease: "power1.out",
        });
        renderKey.value += 1;

    } catch (error) {
        console.error("Error fetching data", error);
    }

    await refresh_audio()

    spinning.value = false
    showBadges.value = true

};

// const get_text_to_speech = async () => {
//     spinning_tts.value = true

//     if (text_audiofile.value && json_transcript.value) {
//         spinning_tts.value = false
//         const audioplayer = new Audio(text_audiofile.value);
//         audioplayer.play();
//         spinning_tts.value = false
//         return true;
//     } else {
//         await create_audio()
//         spinning_tts.value = false
//     }

// }

const create_audio = async () => {
    try {
        const postData = {
            text_sentence: item.value.sentence,
            role: item.value.role,
            audio_speed: audio_speed.value / 100
        };

        const [audioResponse] = await Promise.all([
            axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/sprachtechnik/coursedesigner/text_to_speech`, postData),
        ]);

        const { audio, transcript, audio_url } = audioResponse.data;
        const audioplayer = new Audio(`data:audio/mp3;base64,${audio}`);
        audioplayer.play();
        item.value.json_transcript = JSON.parse(transcript)
        //transcript_msg.value = JSON.parse(transcript)
        item.value.text_audiofile = audio_url

    } catch (error) {
        console.error("Error fetching data", error);
    }
}

const audio_button = async (data) => {

    try {

        const postData = {
            text_sentence: data.sentence,
            role: data.role,
            audio_speed: audio_speed.value / 100
        };

        const [audioResponse] = await Promise.all([
            axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/sprachtechnik/coursedesigner/text_to_speech`, postData),
        ]);

        const { audio, transcript, audio_url } = audioResponse.data;
        const audioplayer = new Audio(`data:audio/mp3;base64,${audio}`);
        audioplayer.play();
    } catch (error) {
        console.error("Error fetching data", error);
    }
}

const audio_button2 = async (data) => {
    console.log(data.text_audiofile);

    try {
        const postData = {
            text_sentence: data.sentence,
            role: data.role,
            audio_speed: audio_speed.value / 100
        };

        let audioResponse;

        if (data.text_audiofile && typeof data.text_audiofile === 'string' && data.text_audiofile.toLowerCase().endsWith('.mp3')) {
            postData.audio_url = data.text_audiofile;
            audioResponse = await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/sprachtechnik/coursedesigner/play`, postData);
        } else {
            audioResponse = await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/sprachtechnik/coursedesigner/text_to_speech`, postData);
        }

        const { audio, transcript, audio_url } = audioResponse.data;

        const audioplayer = new Audio(`data:audio/mp3;base64,${audio}`);
        audioplayer.play();
    } catch (error) {
        console.error("Error fetching data", error);
    }
}

const refresh_audio = async () => {
    spinning_tts.value = true
    await create_audio()
    spinning_tts.value = false
}

const getBadges = () => {
    if (item.value.sentence_tl) {
        return item.value.sentence_tl.split(' ');
    }
}

const getDropdowns = () => {
    return allEntries.value;
}
const getDropdownValues = data => {
    //console.log('getDropdownValues', data);

    item.value.sentence_tl = '';

    const translations = Object.values(data).map(item => item.translation).join(' ');
    item.value.sentence_tl = translations;

    item.value.sentences = Object.entries(data).map(([index, value]) => ({
        word: value.word,
        translation: value.translation
    }));

    //console.log(item.value.sentences);
    item.value.sentence = item.value.sentences.map(sentence => sentence.word).join(' ');
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>