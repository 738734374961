<template>
    <CascadeSelect v-model="selectedVoice" :options="groupedVoices" optionLabel="long" optionGroupLabel="label" optionGroupChildren="items" filter placeholder="Wähle Sprache und Sprachmodell" />
</template>

<script setup>
import { ref, watch, defineEmits } from 'vue';
import CascadeSelect from 'primevue/cascadeselect';
import languages from '../languages.json';
import smlvoices from '../voices.json';
const selectedVoice = ref(null);
const emits = defineEmits(['selectedVoice']);

const voicesData = smlvoices

const languageNames = languages;

const groupedVoices = ref([]);


voicesData.voices.forEach(voice => {
    const languageCode = voice.languageCodes;
    const languageName = languageNames[languageCode];

    if (languageName) {
        let group = groupedVoices.value.find(group => group.label === languageName);
        if (!group) {
            group = {
                label: languageName,
                items: []
            };
            groupedVoices.value.push(group);
        }

        group.items.push({
            ...voice,
            long: `${voice.name} (${voice.ssmlGender})`
        });
    }
});
groupedVoices.value = groupedVoices.value.filter(group => group.items.length > 0);

watch(selectedVoice, (newVal) => {
    emits('selectedVoice', newVal);
});
</script>