<template>
    <Button class="p-button-rounded bg-primary-500" icon="pi pi-play" :loading="spinner" @click="play_audio" />
</template>
<script setup>
import { ref, defineEmits, defineProps, onMounted } from 'vue';
import axios from 'axios'
import languageNames from '../languages.json';
const props = defineProps(['data', 'func'])

const emit = defineEmits(['get']);
const spinner = ref(false);

onMounted(async () => {
  
});


const play_audio = async () => {

    if (props.func) {
        spinner.value = true;
        await props.func(props.data);
        spinner.value = false;
    }else{
        await play_audio_demo();
    }
};

const play_audio_demo = async () => {

    spinner.value = true;

    try {

        const postData = {
            text_sentence: 'Ich bin ein Test Satz',
            language: languageNames[props.data.language.languageCodes[0]],
            language_code: props.data.language.languageCodes[0],
            voice: props.data.language.name,
            ssmlGender: props.data.language.ssmlGender,
            language_selector:props.data.language_selector
        };

        const [audioResponse] = await Promise.all([
            axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/sprachtechnik/coursedesigner/role_editor_play_audiodemo`, postData),
        ]);

        const audio = audioResponse.data;
        const audioplayer = new Audio(`data:audio/mp3;base64,${audio}`);
        audioplayer.play();
        spinner.value = false;

    } catch (error) {
        console.error("Error fetching data", error);
    }
    spinner.value = false;
};
</script>