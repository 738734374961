<template>
    <div>
        <div class="flex gap-2 my-2">
            <Button icon="pi pi-plus" @click="showDialog('add')" label="Rolle hinzufügen" />
        </div>
        <div v-if="items.length > 0">
            <DataTable :value="items" paginator :rows="25" tableStyle="min-width: 100%" rowReorder @rowReorder="onRowReorder">
                <Column rowReorder style="width:3em"></Column>
                <Column field="name" header="Rollenname" sortable></Column>
                <Column header="Sprache">
                    <template #body="slotProps">
                        {{ languageNames[slotProps.data.language.languageCodes[0]] }}
                    </template>
                </Column>
                <Column field="language.languageCodes" header="Language Code" sortable>
                    <template #body="slotProps">
                        {{ slotProps.data.language.languageCodes[0] }}

                    </template>
                </Column>
                <Column field="language.name" header="Stimme" sortable>
                    <template #body="slotProps">
                        {{ slotProps.data.language.name }}

                    </template>
                </Column>
                <Column field="language.ssmlGender" header="SSML Gender" sortable>
                    <template #body="slotProps">
                        {{ slotProps.data.language.ssmlGender }}
                    </template>
                </Column>
                <Column field="language_selector" header="Provider" sortable>
                    <template #body="slotProps">
                        <Chip :label="slotProps.data.language_selector" />
                    </template>
                </Column>
                <Column>
                    <template #body="slotProps">
                        <AudioDemoButton :data="slotProps.data" />
                    </template>
                </Column>
                <Column>
                    <template #body="slotProps">
                        <div class="flex space-x-2">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success" @click="showDialog('edit', slotProps.data)" />
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="deleteItem(slotProps.data)" />
                        </div>
                    </template>
                </Column>
            </DataTable>
        </div>
        <div v-else>
            <Message :closable="false">Keine Rollen vorhanden</Message>
        </div>

        <Dialog v-model:visible="dialogVisible" :header="dialogHeader">
            <div class="my-2">
                <label for="name">Rollen-Name</label>
                <InputText id="name" v-model="item.name" />
            </div>
            <div class="my-2">
                <label for="selector">Sprachenbieter</label>
                <div>
                    <Dropdown id="selector" v-model="item.language_selector" :options="[{ name: 'Google', value: 'google' }, { name: 'Elevenlabs', value: 'elevenlabs' }]" optionLabel="name" optionValue="value" />
                </div>
            </div>

            <div v-if="item.language_selector == 'google'">
                <div class="my-2">
                    <LanguageDropdown @selectedVoice="select_voice" />
                </div>
            </div>

            <div v-if="item.language_selector == 'elevenlabs'">
                <ElevenlabsVoicesDropdown @selectedVoice="select_voice" />
            </div>

            <div class="my-2" v-if="item.language">
                <div>Demo</div>
                <div>
                    <AudioDemoButton :data="item" />
                </div>
            </div>

            <Button label="Rolle Speichern" @click="saveItem" />
        </Dialog>
    </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, onMounted, watchEffect } from 'vue';
import LanguageDropdown from '../Components/Language-Dropdown.vue'

import ElevenlabsVoicesDropdown from '../Components/Elevenlabs-VoicesDropdown.vue'
import languageNames from '../languages.json';
import AudioDemoButton from '../Components/AudioDemo-Button.vue';


const props = defineProps(['data'])
const items = ref(props.data);

const item = ref({ name: '', language: '', voice: '', language_selector: 'google' });
const dialogVisible = ref(false);
const dialogHeader = ref('');
const emit = defineEmits(['get']);


onMounted(() => {
    items.value = props.data
});

watchEffect(() => {
    items.value = props.data;
});



const select_voice = (voice) => {

    if (item.value.language_selector == 'elevenlabs'){
       voice   = {
           languageCodes: [voice.language],
            name: voice.name,
            ssmlGender: false,
            naturalSampleRateHertz: 0,
            long: voice.name
        }
    }
    item.value.language = voice;
};


const showDialog = (type, data = { name: '', language: null }) => {
    if (type === 'edit') {

        item.value = { ...data };
        dialogHeader.value = 'Rolle bearbeiten';
    } else {

        item.value = { name: '', language: null };
        dialogHeader.value = 'Rolle hinzufügen';
    }
    dialogVisible.value = true;
}

const saveItem = () => {
    if (item.value.name) {
        let index;
        if (item.value.id) {
            index = items.value.findIndex(i => i.id === item.value.id);
            if (index !== -1) {
                items.value[index] = { ...item.value };
            }
        } else {

            item.value.id = Date.now();
            items.value.push({ ...item.value });
        }
        dialogVisible.value = false;
        emitItems();
    }
}


const deleteItem = (data) => {
    const index = items.value.findIndex(i => i.id === data.id);
    if (index !== -1) {
        items.value.splice(index, 1);
    }
    emitItems()
};

const onRowReorder = (event) => {
    const { value } = items;
    const draggedItem = value[event.dragIndex];
    value.splice(event.dragIndex, 1);
    value.splice(event.dropIndex, 0, draggedItem);
    items.value = [...value];
    emitItems()
};


const emitItems = () => {
    emit('get', items.value);
};
</script>
