<template>
    <Button label="Audio neu generieren" @click="recreate_audio" class="w-auto my-6 p-button" icon="pi pi-refresh" :loading="recreate_audio_loader" />
</template>
<script setup>

import { ref, defineEmits} from 'vue'
import axios from 'axios'
import { useCustomerStore } from '@/core/store/CustomerStore'

const recreate_audio_loader = ref(false)
const slug = ref(useCustomerStore().getCustomer.customer);
const props = defineProps({
    workflow_id: { type: String, required: true }
})
const emit = defineEmits(['done'])

const recreate_audio = async () => {
    recreate_audio_loader.value = true
    await recreate_course_soundfiles(props.workflow_id)
    recreate_audio_loader.value = false
    emit('done', true)
}

const recreate_course_soundfiles = async workflow_id => {
    try {
        const response = await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/coursedesigner/recreate_audio`, { workflow_id: workflow_id });
        return response.data;
    } catch (error) {
        console.error(error);
        emit('done', false)
    }
}
</script>